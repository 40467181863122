import React, { useState, useEffect } from 'react';
import  { generateInvoicePDF } from '../generateInvoiceComponent/pdfGenerator'

export function MyTable() {
  const [data, setData] = useState([]);
  const userId = localStorage.getItem("user_id")
  useEffect(() => {
    // Ensure the userId is not undefined or null
    if (!userId) return;
    const url = `https://api.rkbillingdto.xyz/fetch_recent_transactions_by_user.php?user_id=${userId}`;
  
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setData(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error.message);
        setData([]); // Reset data or handle errors appropriately
      });
  }, [userId]);

  const handleDownload = async (transPkId) => {
    try {
      const response = await fetch(`https://api.rkbillingdto.xyz/download_details.php?trans_pk_id=${transPkId}`);
      if (!response.ok) throw new Error('Failed to fetch transaction details');
      const transactionDetails = await response.json();
  
      // Call generateInvoicePDF function with the fetched transaction details
      // Assuming generateInvoicePDF is adjusted to accept the transaction details and handle PDF generation
      await generateInvoicePDF(transactionDetails);
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };

  return (
    <div>
  <h3>Latest Transactions</h3>
  <table className="table">
    <thead>
      <tr>
        <th style={{ textAlign: 'center' }}>Transaction ID</th>
        <th style={{ textAlign: 'center' }}>Customer Name</th>
        <th style={{ textAlign: 'right' }}>Sub Total</th>  
        <th style={{ textAlign: 'right' }}>Discount</th>																						
        <th style={{ textAlign: 'right' }}>GST</th>
        <th style={{ textAlign: 'right' }}>Total</th>
        <th style={{ textAlign: 'right' }}>Action</th>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          <td style={{ textAlign: 'center' }}>{item.trans_pk_id}</td>
          <td style={{ textAlign: 'center' }}>{item.customer_name}</td>
          <td style={{ textAlign: 'right' }}>{"₹ "}{item.SubTotal}</td>
          <td style={{ textAlign: 'right' }}>{"₹ "}{item.discount || 0}</td>																							 
          <td style={{ textAlign: 'right' }}>{"₹ "}{item.gst}</td>
          <td style={{ textAlign: 'right' }}>{"₹ "}{item.total}</td>
          <td style={{ textAlign: 'right' }}>
            <button onClick={() => handleDownload(item.trans_pk_id)}>Download</button>	  
          </td>
																													   
        </tr>
      ))}
    </tbody>
  </table>
</div>

  );
}

export default MyTable;
