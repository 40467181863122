import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { BiExit, BiCloudDownload } from "react-icons/bi";
import { generateInvoicePDF } from './pdfGenerator';

function convertNumberToWords(amount) {
  const words = [
      '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
      'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen',
  ];
  const tens = [
      '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety',
  ];

  function numberToWords(n, suffix) {
      if (n === 0) {
          return '';
      } else if (n < 20) {
          return words[n] + suffix;
      } else if (n < 100) {
          return tens[Math.floor(n / 10)] + ' ' + words[n % 10] + suffix;
      } else if (n < 1000) {
          return words[Math.floor(n / 100)] + ' Hundred ' + numberToWords(n % 100, suffix);
      } else if (n < 100000) {
          return numberToWords(Math.floor(n / 1000), ' Thousand ') + numberToWords(n % 1000, suffix);
      } else if (n < 10000000) {
          return numberToWords(Math.floor(n / 100000), ' Lakh ') + numberToWords(n % 100000, suffix);
      } else {
          return numberToWords(Math.floor(n / 10000000), ' Crore ') + numberToWords(n % 10000000, suffix);
      }
  }

  // Splitting the amount into whole and decimal parts
  let [whole, decimal] = amount.toString().split('.');

  let text = numberToWords(parseInt(whole), '');

  // Adding the paise part if present
  if (decimal && parseInt(decimal) > 0) {
      text += ' and ' + numberToWords(parseInt(decimal), '') + ' Paise';
  }

  return text + ' Only';
}


class InvoiceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false, // Add this line
      };
  }
  
closeModel = () => {
  // Add any additional cleanup or actions you want to perform before closing the modal
  this.props.closeModal();
};

updateDatabase = async () => {
  try {
    const response = await fetch(`https://api.rkbillingdto.xyz/add_invoice_data.php`, {
      method: 'POST', // or 'PUT' or 'PATCH' depending on your server implementation
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: localStorage.getItem("user_id"),
        items: this.props.items,
        customerGstin: this.props.info.customerGST,
        dateOfIssue: this.props.info.dateOfIssue,
        billTo: this.props.info.billTo,
        billToEmail: this.props.info.billToEmail,
        billToAddress: this.props.info.billToAddress,
        billToCustomerType: this.props.info.customerType,
        customerGSTIN: this.props.info.customerGST,
        billToType: this.props.info.billType,
        total: this.props.info.total,
        discountAmount: this.props.info.discountAmount,
        taxAmount: this.props.info.taxAmount,
        subTotal: this.props.info.subTotal
      }),
    });

    if (!response.ok) {
      const responseData = await response.json();
      console.log('responseData', responseData)
      throw new Error('Failed to update database');
    }
    const responseData = await response.json();
    const invoiceId = responseData.invoiceId;
    console.log('responseData', responseData)
    console.log('Database updated successfully. Invoice ID:', invoiceId);
    return invoiceId;
  } catch (error) {
    console.error('Error updating database:', error.message);
  }
}

GenerateInvoice = async () => {
  this.setState({ downloading: true })
  // Assuming this.props.info contains all the necessary invoice data
  try {
    const invoiceId = await this.updateDatabase();
    // Call the PDF generation function
    await generateInvoicePDF({ ...this.props.info, invoiceId }); // Assuming it's async or returns a Promise
    this.closeModel()
  } catch (error) {
    console.error('Error generating PDF:', error);
  } finally {
    this.setState({ downloading: false }); // Re-enable the button
  }
};

render() {
  return (
    <div>
      <Modal show={this.props.showModal} onHide={this.props.closeModal} size="xl" centered style={{ maxWidth: '100%', width: '100%' }} >
        <div id="invoiceCapture" className="p-2" centered >
          <h4 style={{ textAlign: 'center', verticalAlign: 'middle' }} className="w-100 p-0" > Tax Invoice </h4>
          <Modal.Body>
            <div className="d-flex flex-row justify-content-between align-items-start bg-light w-100 p-0">
              <Col md={2} lg={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <img src="logo_RK.png"
                  alt="logo"
                  style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                />
              </Col>
              <Col md={7} lg={7}>
                <div className="w-100 p-1">
                  <h3 className="fw-bold mb-1" style={{ color: 'rgb(255, 87, 50)' }}> M/s. {this.props.info.billFrom}</h3>
                  <h8 className="fw-bold text-secondary mb-1"> {this.props.info.billFromEmail}</h8>
                  <h6 className="fw-bold text-secondary mb-1">
                    GSTIN: {'GST-20AKUPG1681M1ZW'}
                  </h6>
                  <h6 className="fw-bold text-secondary mb-1">
                    Invoice Date: {this.props.info.dateOfIssue || ''}
                  </h6>
                
                </div>
              </Col>
              {/* <Col md={3} lg={3}> </Col> */}
              <Col md={3} lg={3}>
                <div className="text-end ms-6 p-3">
                  <h6 className="fw-bold mt-1 mb-2">Amount:&nbsp;</h6>
                  <h5 className="fw-bold text-secondary"> {this.props.total}</h5>
                  <div className="fw-bold text-secondary">Customer Type: {this.props.info.customerType}</div>
                  <div className="fw-bold text-secondary">Bill Type: {this.props.info.billType}</div>
                </div>
              </Col>
            </div>
            <div className="p-2">
              <Row className="mb-2">
                <Col md={6} lg={6}>
                  <div className="fw-bold">Billed to:</div>
                  <div>{this.props.info.billTo || ''}</div>
                  <div>{this.props.info.billToAddress || ''}</div>
                  <div>{this.props.info.billToEmail || ''}</div>
                  <div>{this.props.info.isValidGST && this.props.info.billType === 'Pakka Bill' && (
                    <div>
                      {'GSTIN: '}{this.props.info.customerGST || ''}
                    </div>
                  )}</div>
                </Col>
                <Col md={6} lg={6}>
                  <div className="fw-bold">Billed From:</div>
                  <div>{this.props.info.billFrom || ''}</div>
                  <div>{this.props.info.billFromAddress || ''}</div>
                  <div>{this.props.info.billFromMobile || ''}</div>
                </Col>
              </Row>
              <Table >
                <thead>
                  <tr>
                    <th className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>Sr No.</th>
                    <th className="w-3/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>ITEM</th>
                    <th className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>HSN</th>
                    <th className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>PARTICULARS</th>
                    <th className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>LOCATION</th>
                    <th className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>QTY</th>
                    <th className="w-2/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>RATE</th>
                    <th className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>DISC</th>
                    <th className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>GST</th>
                    <th className="w-2/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.items.map((item, i) => {
                    return (
                      <tr id={i} key={i}>
                        <td className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>{i + 1}</td>
                        <td className="w-3/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>{item.name}</td>
                        <td className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>{item.hsnCode}</td>
                        <td className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>{item.particulars}</td>
                        <td className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}> {item.location} </td>
                        <td className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>{item.quantity}</td>
                        <td className="w-2/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>{item.price}</td>
                        <td className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>
                          <div style={{ alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                            <span>{item.discountForSelectedItem}</span>
                            <span className="small ">({item.discount || 0}%)</span>
                          </div>
                        </td>
                        <td className="w-1/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>
                          <div style={{ alignContent: 'center', display: 'flex', flexDirection: 'column' }}>
                            <span>{item.gstForSelectedItem}</span>
                            <span className="small ">({item.gstRate || 0}%)</span>
                          </div>
                        </td>
                        <td className="w-2/12 px-1 py-1 text-center" style={{ border: '1px solid black', verticalAlign: 'middle' }}>
                          {item.discountedAmountForSelectedItem}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="w-1/12 px-1 py-1 text-center"></td>
                    <td className="w-3/12 px-1 py-1 text-center"></td>
                    <td className="w-1/12 px-1 py-1 text-center"></td>
                    <td className="w-1/12 px-1 py-1 text-center"></td>
                    <td className="w-1/12 px-1 py-1 text-center"></td>
                    <td className="w-1/12 px-1 py-1 text-center"></td>
                    <td className="w-1/12 px-1 py-1 text-center fw-bold" style={{ border: '1px solid black', verticalAlign: 'middle' }}>SUBTOTAL</td>
                    <td className="w-1/12 px-1 py-1 text-center fw-bold" style={{ border: '1px solid black', verticalAlign: 'middle' }}>{this.props.discountAmount}</td>
                    <td className="w-2/12 px-1 py-1 text-center fw-bold" style={{ border: '1px solid black', verticalAlign: 'middle' }}>{this.props.taxAmount}</td>
                    <td className="w-1/12 px-1 py-1 text-center fw-bold" style={{ border: '1px solid black', verticalAlign: 'middle' }}>{this.props.subTotal}</td>
                  </tr>
                </tfoot>
              </Table>
              <Row>
                <Col sm={6} md={6} lg={6} className="p-2">
                  <div>
                    <h5 className="fw-bold">TERMS AND CONDITIONS</h5>
                    <ol>
                      <li>Goods once sold will not be taken back or exchanged</li>
                      <li>All disputes are subject to PALAMU jurisdiction only</li>
                    </ol>
                  </div>
                </Col>
                <Col sm={6} md={6} lg={6} className="p-2">
                  <div className="d-flex flex-row align-items-start justify-content-between mb-1">
                    <span className="fw-bold">Taxable amount:</span>
                    <span> {this.props.subTotal}</span>
                  </div>
                  <div className="d-flex flex-row align-items-start justify-content-between mt-1 mb-1">
                    <span className="fw-bold">CGST:</span>
                    <span> {this.props.taxAmount / 2 || 0}</span>
                  </div>
                  <div className="d-flex flex-row align-items-start justify-content-between mt-1 mb-1">
                    <span className="fw-bold">SGST:</span>
                    <span> {this.props.taxAmount / 2 || 0}</span>
                  </div>
                  <div className="d-flex flex-row align-items-start justify-content-between mt-1" style={{ fontSize: '1.125rem' }}>
                    <span className="fw-bold">Total:</span>
                    <span className="fw-bold">{this.props.total || 0}</span>
                  </div>
                </Col>
                <Col className="p-2">
                  <div className="py-1 px-1 rounded">
                    <Table border="0"> {/* Added border="0" to hide borders */}
                      <thead>
                        <th style={{ width: '75%', fontSize: '10pt', textAlign: 'left', verticalAlign: 'middle' }}>Total (in words)</th>
                        <th style={{ width: '25%', fontSize: '10pt', textAlign: 'center', verticalAlign: 'middle' }}>For R. K. ENTERPRISES</th>
                      </thead>
                      <tbody>
                        <td style={{ width: '75%', textAlign: 'left', verticalAlign: 'middle' }}>{convertNumberToWords(this.props.total)}</td>
                        <td style={{ width: '25%', textAlign: 'center', verticalAlign: 'middle' }}> <img src="sign.PNG"
                          alt="Invoice"
                          style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
                        /></td>
                      </tbody>
                      <tfoot>
                        <th style={{ width: '75%', fontSize: '8pt', textAlign: 'center', verticalAlign: 'middle' }}></th>
                        <th style={{ width: '25%', fontSize: '8pt', textAlign: 'center', verticalAlign: 'middle' }}>Authorised Signatory</th>
                      </tfoot>
                    </Table>
                  </div>
                </Col>
              </Row>

            </div>
            {this.props.info.notes &&
              <div className="bg-light py-1 px-1 rounded">
                <span className="fw-bold"> Notes: </span>
                {this.props.info.notes}
              </div>}
            <h6 style={{ textAlign: 'center' }} > *This is a Computer Generated Invoice. *</h6>

          </Modal.Body>
        </div>
        <div className="pb-4 px-4">
          <Row>
            <Col md={6}>
              <Button variant="primary" className="d-block w-100" onClick={this.closeModel}>
                <BiExit style={{ width: '15px', height: '15px', marginTop: '-3px' }} className="me-2" />Exit
              </Button>
            </Col>
            <Col md={6}>
              <Button variant="outline-primary" className="d-block w-100 mt-3 mt-md-0" onClick={this.GenerateInvoice} disabled={this.state.downloading}>
                <BiCloudDownload style={{ width: '16px', height: '16px', marginTop: '-3px' }} className="me-2" />
                Download
              </Button>
            </Col>
          </Row>
        </div>
      </Modal >
      <hr className="mt-4 mb-3" />
    </div >
  )
}
}

export default InvoiceModal;
