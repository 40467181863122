import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'


const logo = "logo_RK.png"; 
const sign = "sign.PNG";

function convertNumberToWords(amount) {
    const words = [
        '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
        'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen',
    ];
    const tens = [
        '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety',
    ];

    function numberToWords(n, suffix) {
        if (n === 0) {
            return '';
        } else if (n < 20) {
            return words[n] + suffix;
        } else if (n < 100) {
            return tens[Math.floor(n / 10)] + ' ' + words[n % 10] + suffix;
        } else if (n < 1000) {
            return words[Math.floor(n / 100)] + ' Hundred ' + numberToWords(n % 100, suffix);
        } else if (n < 100000) {
            return numberToWords(Math.floor(n / 1000), ' Thousand ') + numberToWords(n % 1000, suffix);
        } else if (n < 10000000) {
            return numberToWords(Math.floor(n / 100000), ' Lakh ') + numberToWords(n % 100000, suffix);
        } else {
            return numberToWords(Math.floor(n / 10000000), ' Crore ') + numberToWords(n % 10000000, suffix);
        }
    }

    // Splitting the amount into whole and decimal parts
    let [whole, decimal] = amount.toString().split('.');

    let text = numberToWords(parseInt(whole), '');

    // Adding the paise part if present
    if (decimal && parseInt(decimal) > 0) {
        text += ' and ' + numberToWords(parseInt(decimal), '') + ' Paise';
    }

    return text + ' Only';
}

export const generateInvoicePDF = (invoiceData) => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.width;
    const rightMargin = 15;
    pdf.setFontSize(18);
    pdf.setFont(undefined, 'bold');
    pdf.text('Tax Invoice', 105, 12, null, null, 'center');
    pdf.setFont(undefined, 'normal');

    // Draw light gray rectangle as background for seller info block
    const bgColor = '#f0f0f0'; // Light gray
    const rectStartX = 10; // X-coordinate where the rectangle starts
    const rectStartY = 20; // Y-coordinate where the rectangle starts
    const rectWidth = pageWidth- (2*rectStartX) ; // Width of the rectangle
    const rectHeight = 35; // Height of the rectangle, adjust as needed
    pdf.setFillColor(bgColor);
    pdf.rect(rectStartX, rectStartY, rectWidth, rectHeight, 'F'); // 'F' stands for filled

    // Adding logo - adjust x, y, width, height as needed
    pdf.addImage(logo, 'PNG', 10, 22, 30, 30); // Adjust coordinates and dimensions as needed

    // Seller Information aligned with the logo
    pdf.setFontSize(12);
    pdf.text(`M/s. R. K. ENTERPRISES`, 45, 30); // Adjust y-coordinate to align with the bottom of the logo if needed
    pdf.text('anilkumargupta1800@gmail.com', 45, 35);
    pdf.text(`GSTIN: GST-20AKUPG1681M1ZW`, 45, 40);
    pdf.text(`Invoice Date: ${invoiceData.dateOfIssue}`, 45, 45);
    if (invoiceData.customerGST) {
    pdf.text(`Ref: ${invoiceData.invoiceId}`, 45, 50);
    } else {
    pdf.text(`Ref: `, 45, 50);
    }
    // Amount Due, Customer Type, Bill Type aligned to the right
    pdf.text(`Amount: ${invoiceData.total}`, 155, 35); // Adjust x-coordinate to align to the right
    pdf.text(`Customer Type: ${invoiceData.customerType}`, 155, 40);
    pdf.text(`Bill Type: ${invoiceData.billType}`, 155, 45);

    // Billed To and From
    pdf.setFontSize(14);
    pdf.setFont(undefined, 'bold');
    pdf.text('Billed To:', 15, 65);
    pdf.setFont(undefined, 'normal');
    pdf.setFontSize(12);
    pdf.text(invoiceData.billTo, 15, 72);
    pdf.text(invoiceData.billToEmail, 15, 78);
    pdf.text(`GSTIN: ${invoiceData.customerGST || 'N/A'}`, 15, 84);
    const splitToAddress = pdf.splitTextToSize(invoiceData.billToAddress, 95 );
    pdf.text(splitToAddress, 15, 90);


    pdf.setFontSize(14);
    pdf.setFont(undefined, 'bold');
    pdf.text('Billed From:', 120, 65);
    pdf.setFont(undefined, 'normal');
    pdf.setFontSize(12);
    pdf.text(`M/s. R. K. ENTERPRISES`, 120, 72);
    pdf.text('+91 95341 25511', 120,  78);
    const add = 'CHIANKI, DALTONGANH, PALAMU, JHARKHAND PIN : 822101'
    const splitAddress = pdf.splitTextToSize(add, pageWidth - rightMargin - 120 );
    pdf.text(splitAddress, 120, 84);
    

    // Table Headers
    const headers = [
        { header: 'Sr No.', dataKey: 'srNo' },
        { header: 'ITEM', dataKey: 'item' },
        { header: 'HSN', dataKey: 'hsn' },
        { header: 'PART.', dataKey: 'particulars' },
        { header: 'LOC.', dataKey: 'location' },
        { header: 'QTY', dataKey: 'quantity' },
        { header: 'RATE', dataKey: 'rate' },
        { header: 'DISC', dataKey: 'disc' },
        { header: 'GST', dataKey: 'gst' },
        { header: 'AMOUNT', dataKey: 'amount' },
    ];


    // Convert items for table
    const itemsForTable = invoiceData.items.map((item, index) => ([
        index + 1, // Sr No.
        item.name, // ITEM
        item.hsnCode, // HSN
        item.particulars, // PARTICULARS
        item.location, // LOCATION
        item.quantity.toString(), // QTY - Ensure conversion to string
        item.price.toString(), // RATE - Ensure conversion to string
        item.discountForSelectedItem.toString(), // DISC - Ensure conversion to string
        item.gstForSelectedItem.toString(), // GST - Ensure conversion to string
        item.discountedAmountForSelectedItem.toString(), // AMOUNT - Ensure conversion to string
    ]));

    const tableWidth = pageWidth - 25;
    // Revised autoTable call
    autoTable(pdf, {
        head: [headers.map(header => header.header)], // Convert your headers to the expected format
        body: itemsForTable, // Use the adjusted items array
        startX: 15,
        startY: 100,
        tableWidth: tableWidth,
        foot: [[
            '', '', '', '', '', '', 'TOTAL',
            invoiceData.discountAmount,
            invoiceData.taxAmount,
            invoiceData.subTotal,
        ]],
        theme: 'grid',
        styles: {
            // Styles applied to all cells
            fontSize: 10,
        },
        headStyles: {
            fillColor: [220, 220, 220], // Light grey background for headers
            textColor: [0, 0, 0], // Black text color for headers
            // You can add other styles here as needed
        },
        footStyles: {
            fillColor: [220, 220, 220], // Light grey, for example
            textColor: [0, 0, 0], // Dark grey text color
            // Add other styling options as needed
        }, 
        columnStyles: {
             // Sr No. column - left aligned
            1: { halign: 'left' }, // ITEM column - left aligned
            // Specify other columns as left-aligned as needed
            6: { halign: 'right' }, // RATE column - right aligned
            7: { halign: 'right' }, // DISC column - right aligned
            8: { halign: 'right' }, // GST column - right aligned
            9: { halign: 'right' }, // AMOUNT column - right aligned
        },    
    });
    
    const startY = pdf.lastAutoTable.finalY + 10;
    // Function to calculate the x position for right-aligned text
    const calculateRightAlignedX = (text, fontSize, margin) => {
    pdf.setFontSize(fontSize);
    const textWidth = pdf.getTextWidth(text);
    return pageWidth - margin - textWidth;
    };

    // Example usage
    const fontSize = 12; // Example font size
    pdf.setFontSize(fontSize); // Set the font size for accurate width calculation
    const subTotalText = `Taxable amount: ${invoiceData.subTotal}`;
    const cgstText = `CGST: ${(invoiceData.taxAmount / 2).toFixed(2)}`;
    const sgstText = `SGST: ${(invoiceData.taxAmount / 2).toFixed(2)}`;
    const totalText = `Total: ${invoiceData.total}`;

    // Calculate right-aligned x positions
    const xSubTotal = calculateRightAlignedX(subTotalText, fontSize, rightMargin +2);
    const xCgst = calculateRightAlignedX(cgstText, fontSize, rightMargin);
    const xSgst = calculateRightAlignedX(sgstText, fontSize, rightMargin);
    const xTotal = calculateRightAlignedX(totalText, fontSize, rightMargin+1);
    
    pdf.setFont(undefined, 'bold');
    pdf.text(subTotalText, xSubTotal, startY);
    pdf.text(cgstText, xCgst, startY + 5);
    pdf.text(sgstText, xSgst, startY + 10);
    pdf.text(totalText, xTotal, startY + 15);
    pdf.setFont(undefined, 'normal');
    pdf.setFontSize(10)
    // const amountInText = convertNumberToWords(invoiceData.total)
    const amountText =  pdf.splitTextToSize(convertNumberToWords(invoiceData.total), 50 );
    pdf.text(`Total (in words):`, 15, startY + 20);
    pdf.text(`${amountText}`, 15, startY + 24);
 
    // pdf.setFontSize(10)
    // Terms and Conditions
    pdf.text('Terms and Conditions:', 15, startY + 34);
    pdf.text('Goods once sold will not be taken back or exchanged.', 15, startY+ 39);
    pdf.text('All disputes are subject to PALAMU jurisdiction only.',15, startY+ 44)


    const nameSign  = 'For R. K. ENTERPRISES'
    const xSign = calculateRightAlignedX(nameSign, fontSize, rightMargin);
    // Authorised Signatory
    pdf.text(nameSign, xSign, startY + 30);
    pdf.addImage(sign, 'PNG', xSign + 2, startY + 32, 40, 10 );
    pdf.text('Authorised Signatory', xSign+2, startY + 47);

    // Notes
    pdf.setFontSize(10)
    if (invoiceData.notes) {
        pdf.text('Notes:', 15, startY + 60);
        pdf.text(invoiceData.notes, 15, startY + 64);
    }

    // Footer
    pdf.setFontSize(8);
    pdf.text('*This is a Computer Generated Invoice', 105, 280, null, null, 'center');

    // Save the PDF
    pdf.save(`Invoice-${invoiceData.invoiceId}.pdf`);
};
